import { NineHeading } from "@9amhealth/wcl/generated/react";
import type { FC } from "react";
import React from "react";

import FileType from "src/constants/fileType";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import type { CardDetailsFormValues } from "src/state/PatientInsuranceBloc/PatientInsuranceBloc";
import PatientInsuranceBloc, {
  AddInsuranceDocuments
} from "src/state/PatientInsuranceBloc/PatientInsuranceBloc";
import { useBloc } from "src/state/state";
import BlockingLoadingOverlayController from "src/ui/components/BlockingLoadingOverlay/BlockingLoadingOverlayController";
import DocumentUploader from "src/ui/components/DocumentUploader/DocumentUploader";
import Translate from "src/ui/components/Translate/Translate";
import { AutoForm, AutoFormInputField, Button } from "@9amhealth/shared";
import { z } from "zod";
import styled from "@emotion/styled";

const FormFieldsWrapper = styled.div`
  padding: 0 0.6rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export enum AddPharmacyInsuranceInfoAction {
  ADD_CARD_DETAILS = "add-card-details",
  ADD_CARD_PHOTOS = "add-card-photos"
}

const MultiStepPharmacyInsuranceForm: FC<{
  onInsuranceSaved?: () => void;
  onCanceled?: () => void;
}> = ({ onInsuranceSaved, onCanceled }) => {
  const [
    ,
    {
      addInsuranceFormFields,
      temporaryCardDetailFormValues,
      temporaryCardDetailFiles,
      savePharmacyInsurance
    }
  ] = useBloc(PatientInsuranceBloc);
  const [allFilesSelected, setAllFilesSelected] = React.useState(false);
  const [step, setStep] = React.useState<AddPharmacyInsuranceInfoAction>(
    AddPharmacyInsuranceInfoAction.ADD_CARD_DETAILS
  );

  const schema = z.object({
    cardHolderId: z.string().min(1).max(20),
    rxPcn: z.string().min(1).max(10),
    rxBin: z.string().min(1).max(8),
    rxGrp: z.string().min(1).max(15)
  });

  const handleFormSubmit = (values: CardDetailsFormValues) => {
    temporaryCardDetailFormValues.current = values;
    setStep(AddPharmacyInsuranceInfoAction.ADD_CARD_PHOTOS);
  };

  const handleFileUploadChange = (
    fileIds: Record<AddInsuranceDocuments, string>,
    allSelected: boolean
  ) => {
    setAllFilesSelected(allSelected);
    temporaryCardDetailFiles.current = fileIds;
  };

  const handleSaveCard = () => {
    if (
      !temporaryCardDetailFormValues.current ||
      !temporaryCardDetailFiles.current ||
      !allFilesSelected
    ) {
      return;
    }

    BlockingLoadingOverlayController.startLoading({
      bg: "transparent"
    });
    void savePharmacyInsurance(
      temporaryCardDetailFormValues.current,
      temporaryCardDetailFiles.current
    )
      .then(() => {
        BlockingLoadingOverlayController.loadingSuccess();
        onInsuranceSaved?.();
        setAllFilesSelected(false);
        temporaryCardDetailFormValues.current = null;
        temporaryCardDetailFiles.current = null;
      })
      .catch((e: unknown) => {
        reportErrorSentry(e);
        BlockingLoadingOverlayController.loadingError({
          retry: () => {
            handleSaveCard();
          },
          onCancel: () => {
            setAllFilesSelected(false);
            temporaryCardDetailFormValues.current = null;
            temporaryCardDetailFiles.current = null;
            onCanceled?.();
          }
        });
      });
  };

  return (
    <>
      {step === AddPharmacyInsuranceInfoAction.ADD_CARD_DETAILS && (
        <div>
          <NineHeading>
            <h3 className="as-h4-large">
              <Translate msg="insurance.enterDetails.title" />
            </h3>
            <nine-spacer s="md"></nine-spacer>
            <p className="m0 color-c-80">
              <Translate msg="insurance.enterDetails.description" />
            </p>
          </NineHeading>
          <nine-spacer s="lg"></nine-spacer>

          <AutoForm schema={schema} onSubmit={handleFormSubmit}>
            <FormFieldsWrapper>
              <>
                {addInsuranceFormFields.map((field) => {
                  return (
                    <AutoFormInputField
                      key={field.name}
                      name={field.name}
                      label={translate(field.label)}
                      isRequired
                    />
                  );
                })}
              </>
              <div style={{ justifyContent: "center", display: "flex" }}>
                <Button type="submit">
                  <Translate msg="continue" />
                </Button>
              </div>
            </FormFieldsWrapper>
          </AutoForm>
        </div>
      )}

      {step === AddPharmacyInsuranceInfoAction.ADD_CARD_PHOTOS && (
        <div>
          <NineHeading>
            <h3 className="as-h4-large">
              <Translate msg="insurance.uploadPhoto" />
            </h3>
          </NineHeading>
          <nine-spacer s="lg"></nine-spacer>
          <DocumentUploader
            config={{
              fileTypes: ["image/jpeg", "image/png"],
              files: {
                [AddInsuranceDocuments.FRONT]: {
                  title: translate("update.frontSide"),
                  tags: ["pharmacy-insurance-card-front"],
                  namePrefix: "Pharmacy Insurance Card Front",
                  metaFileType: FileType.PHARMACY_INSURANCE_CARD
                },
                [AddInsuranceDocuments.BACK]: {
                  title: translate("update.backSide"),
                  tags: ["pharmacy-insurance-card-back"],
                  namePrefix: "Pharmacy Insurance Card Back",
                  metaFileType: FileType.PHARMACY_INSURANCE_CARD
                }
              }
            }}
            onChange={handleFileUploadChange}
          />

          <div style={{ justifyContent: "center", display: "flex" }}>
            <Button isDisabled={!allFilesSelected} onPress={handleSaveCard}>
              <Translate msg="confirm" />
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
export default MultiStepPharmacyInsuranceForm;
